<template>
  <div class="mt-4 mx-4 elevation-10 rounded-lg pa-4" >
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <span>Sales Contributions By Distributor</span>
        <PieSalesContributionsByFtSalesh
            ref="refPieSalesContributionByDivision"
            :ftSaleshFilter="ftSaleshFilter"
            jenis="DIVISION"
        ></PieSalesContributionsByFtSalesh>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <span>Sales Contributions By Channel</span>
        <PieSalesContributionsByFtSalesh
            ref="refPieSalesContributionByChannel"
            :ftSaleshFilter="ftSaleshFilter"
            jenis="CHANNEL"
        ></PieSalesContributionsByFtSalesh>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <span>Sales Contributions By Jenis Customer</span>
        <PieSalesContributionsByFtSalesh
            ref="refPieSalesContributionByCustomerGroup"
            :ftSaleshFilter="ftSaleshFilter"
            jenis="CUSTOMER-GROUP"
        ></PieSalesContributionsByFtSalesh>
      </v-col>

      <v-col cols="12" sm="6" md="4" >
        <span>Sales Contributions By Brand</span>
        <PieSalesContributions
            brandOrGroup3Supplier="BRAND"
            :brandLabels="brandLabels"
            :brandSeries="brandSeries"
        ></PieSalesContributions>
      </v-col>
      <v-col cols="12" sm="6" md="4" >
        <span>Sales Contributions By Produk Grup</span>
        <PieSalesContributions
            brandOrGroup3Supplier="GROUP3"
            :group3Labels="group3Labels"
            :group3Series="group3Series"
        ></PieSalesContributions>
      </v-col>


      <v-col cols="12" sm="6" md="4"  v-if="false">
        <MyChart2></MyChart2>
      </v-col>

      <v-col cols="12" sm="6" md="4" v-if="false">
        <span>Sales By Brand</span>
        <SalesTrendByBrand></SalesTrendByBrand>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="false">
        <span>Sales By Channel</span>
        <SalesTrendByChannel></SalesTrendByChannel>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="false">
        <span>Sales By Distributor/Salesman</span>
        <SalesPerSalesforce></SalesPerSalesforce>
      </v-col>
      <v-col cols="12" sm="6" md="4" v-if="false">
        <span>Achievement Sales Vs Target</span>
        <AchievementSalesVsTarget></AchievementSalesVsTarget>
      </v-col>

      <v-col cols="12" sm="6" md="4" v-if="false">
        <span>Trend Return Ratio</span>
        <TrendReturnRatio></TrendReturnRatio>
      </v-col>

    </v-row>

    <v-row v-if="false">
      <v-col cols="12" sm="6" md="4">
        <TrendInventory></TrendInventory>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <SalesTrendByBrand></SalesTrendByBrand>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <AchievementSalesVsTarget></AchievementSalesVsTarget>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import MyChart2 from "@/components/laporan/analisis/MyChartDonut";
import PieSalesContributions from "@/components/laporan/analisis/sales-inventory/PieSalesContributionsByBrand";
import TrendReturnRatio from "@/components/laporan/analisis/sales-inventory/TrendReturnRatio";
import AchievementSalesVsTarget from "@/components/laporan/analisis/sales-inventory/AchievementSalesVsTarget";
import SalesPerSalesforce from "@/components/laporan/analisis/sales-inventory/SalesPerSalesforce";
import SalesTrendByBrand from "@/components/laporan/analisis/sales-inventory/SalesTrendByBrand";
import SalesTrendByChannel from "@/components/laporan/analisis/sales-inventory/SalesTrendByChannel";
import PieSalesContributionsByFtSalesh
  from "@/components/laporan/analisis/sales-inventory/PieSalesContributionsFtSalesh";
import TrendInventory from "@/components/laporan/analisis/sales-inventory/TrendInventory";


export default {
  name: "DashboardSalesContrib",
  props:{
    ftSaleshFilter: Object,
    brandLabels: [],
    brandSeries: [],
    group3Labels: [],
    group3Series: []
  },
  components:{
    TrendInventory,
    PieSalesContributionsByFtSalesh,
    SalesTrendByChannel,
    SalesTrendByBrand,
    SalesPerSalesforce,
    AchievementSalesVsTarget,
    TrendReturnRatio,
    PieSalesContributions,
    MyChart2,
  },
  data: () => ({
    labels: [
      '9pm',
    ],
    value: [
      200,
    ],
  }),
  methods:{
    reloadChildren(ftSaleshFilter){
        this.$refs.refPieSalesContributionByDivision.reloadData(ftSaleshFilter)
      this.$refs.refPieSalesContributionByChannel.reloadData(ftSaleshFilter)
      this.$refs.refPieSalesContributionByCustomerGroup.reloadData(ftSaleshFilter)
    }
  }
}
</script>

<style scoped>
.v-sheet--offset {
  top: -12px;
  position: relative;
}
</style>