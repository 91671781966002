<template>
  <div>
    <apexcharts width="350" :options="chartOptions" :series="series"></apexcharts>

  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "SalesTrendByChannel",
  components: {
    apexcharts: VueApexCharts,
  },
  data: function() {
    return {
      chartOptions: {

        chart: {
          height: 350,
          type: 'area',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'datetime',
          categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
        },
        tooltip: {
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 320,
              zoom: {
                enabled: true
              },
            },
          }
        }]


      },

      series: [{
        name: 'Salesman B',
        data: [31, 40, 28, 51, 42, 109, 100]
      }, {
        name: 'Salesman A',
        data: [11, 32, 45, 32, 34, 52, 41]
      }]

    }
  },
}
</script>

<style scoped>

</style>