<template>
  <div>
    <apexcharts
        type="donut"
        width="350"
        :options="chartOptions"
        :series="chartOptions.series"
    ></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "MyChartDunut",
  components: {
    apexcharts: VueApexCharts,
  },
  data: function() {
    return {

      series1: [2, 4, 5, 6],
      labels1: ['a', 'b', 'c', 'd'],

    }
  },
  computed:{

    chartOptions: function() {
      return {
        colors: ['#E60B13', '#1F1E1E', '#6D6D6D', '#CECECE', 'rgba(255,87,93,.77)'],
        legend: {
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          itemMargin: {
            vertical: 10
          },
          formatter: function(seriesName, opts) {
            return '<div class="legend-info">' + '<span>' + seriesName + '</span>' + '<span>' + opts.w.globals.series[opts.seriesIndex] + '</span>' + '</div>'
          }
        },
        dataLabels: {
          enabled: false,
        },

        labels: ['data', 'data', 'data', 'data', 'data'],
        fill: {
          type: 'gradient',
        },

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '22px',
                  fontFamily: 'Rubik',
                  color: '#dfsda',
                  offsetY: -10
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  color: undefined,
                  offsetY: 16,
                  formatter: function (val) {
                    return val
                  }
                },
                total: {
                  show: true,
                  label: 'Total',
                  color: '#373d3f',
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)
                  }
                }

              }
            }
          }
        },
        series: [400, 400, 400, 400, 400]
      }
    },

  }

}
</script>

<style scoped>

</style>