<template>
  <div>
    <apexcharts
        width="350"
        :options="chartOptionsComputed"
        :series="series"
        @click="clickHandler"
    ></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import FtSaleshExtService from "@/services/apiservices/ft-sales-ext-service";

export default {
  name: "PieSalesContributionsByDivision",
  props:{
    ftSaleshFilter: Object,
    jenis: String
  },
  components: {
    apexcharts: VueApexCharts,
  },
  watch:{
    ftSaleshFilter:{
      handler:function (value){
        this.reloadData(value)
      }
    }

  },
  data: function() {
    return {
      // labels: ['Product A', 'Product B', 'Product C', 'Product D', 'Product E'],
      // series: [44, 55, 13, 43, 22],
      labels: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'],
      series: [1,2,3,4,5,6,7,8,9, 10, 12, 13, 14, 15],

    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },

    chartOptionsComputed() {
      let chartOptions= {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: this.labels,

        /**
         * Kasih gradient lebih cantik dikit
         */
        fill: {
          type: 'gradient',
        },

        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
              type: 'pie'
            },
            legend: {
              position: 'bottom'
            }
          },
        }]
      }
      return chartOptions
    }
  },
  methods:{
    clickHandler(event, chartContext, config){
      console.log(event)
      console.log(event.dataPointIndex)
      console.log(chartContext)
      console.log(config.seriesIndex)
      console.log(config)
    },

    reloadData(value){

      if (this.jenis === "DIVISION"){

        // console.log(`${this.currentUser.organizationLevel} >> ${JSON.stringify(value)}`)

        if(this.currentUser.organizationLevel.includes("DIV") ){
          FtSaleshExtService.getAllFtSaleshExtBySalesman(value).then(
              response =>{
                // console.log(JSON.stringify(response.data))

                this.series = []
                this.labels = []
                response.data.sort((a,b) => (a.sumResult < b.sumResult)? 1 : -1 ).forEach( item => {
                      // console.log(item)
                      this.series.push(parseInt(item.sumResult))

                      let shortName =  item.description
                      if (item.description.length > 10){
                        shortName = item.description.substr(0, 9)
                      }
                      this.labels.push(shortName)
                    }
                )

              }
          )

        }else {
          FtSaleshExtService.getAllFtSaleshExtByDivision(value).then(
              response =>{
                // console.log(JSON.stringify(response.data))

                this.series = []
                this.labels = []
                response.data.sort( (a, b) => (a.sumResult < b.sumResult)? 1 : -1).forEach( item => {
                      // console.log(item)
                      this.series.push(parseInt(item.sumResult))

                      let shortName =  item.description
                      if (item.description.length > 10){
                        shortName = item.description.substr(0, 9)
                      }
                      this.labels.push(shortName)
                    }
                )



              }
          )

        }
      }else if (this.jenis === "CHANNEL"){

        // console.log(`${this.currentUser.organizationLevel} >> ${JSON.stringify(value)}`)

        FtSaleshExtService.getAllFtSaleshExtByChannel(value).then(
            response =>{
              // console.log(JSON.stringify(response.data))

              this.series = []
              this.labels = []
              response.data.sort( (a, b) => (a.sumResult < b.sumResult)? 1: -1) .forEach( item => {
                    // console.log(item)
                    this.series.push(parseInt(item.sumResult))

                    let shortName =  item.description
                    if (item.description.length > 10){
                      shortName = item.description.substr(0, 9)
                    }
                    this.labels.push(shortName)
                  }
              )


            }
        )
      }else if (this.jenis === "CUSTOMER-GROUP"){

        // console.log(`${this.currentUser.organizationLevel} >> ${JSON.stringify(value)}`)

        FtSaleshExtService.getAllFtSaleshExtByCustomerGroup(value).then(
            response =>{

              // console.log(JSON.stringify(response.data))

              this.series = []
              this.labels = []
              response.data.sort( (a, b) => (a.sumResult < b.sumResult)? 1: -1) .forEach( item => {
                    // console.log(item)
                    this.series.push(parseInt(item.sumResult))

                    let shortName =  item.description
                    if (item.description.length > 10){
                      shortName = item.description.substr(0, 9)
                    }
                    this.labels.push(shortName)
                  }
              )


            }
        )
      }



    }
  }

}
</script>

<style scoped>

</style>