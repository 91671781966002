import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FtSalesExtService {
    getApiUrl() {
        return API_URL
    }

    getAllFtSaleshExtByDivision(itemFilter){
        return axios.post(API_URL + `getAllFtSaleshExtByDivision`, itemFilter, { headers: authHeader() });
    }
    getAllFtSaleshExtByDivisionAndMonth(itemFilter){
        return axios.post(API_URL + `getAllFtSaleshExtByDivisionAndMonth`, itemFilter, { headers: authHeader() });
    }
    getAllFtSaleshExtByCustomerGroupAndMonth(itemFilter){
        return axios.post(API_URL + `getAllFtSaleshExtByCustomerGroupAndMonth`, itemFilter, { headers: authHeader() });
    }

    getAllFtSaleshExtByChannel(itemFilter){
        return axios.post(API_URL + `getAllFtSaleshExtByChannel`, itemFilter, { headers: authHeader() });
    }
    getAllFtSaleshExtByCustomerGroup(itemFilter){
        return axios.post(API_URL + `getAllFtSaleshExtByCustomerGroup`, itemFilter, { headers: authHeader() });
    }

    getAllFtSaleshExtBySalesman(itemFilter){
        return axios.post(API_URL + `getAllFtSaleshExtBySalesman`, itemFilter, { headers: authHeader() });
    }


    getAllFtSaleshExtByGroup3AndBrandAndSupplier(itemFilter){
        return axios.post(API_URL + `getAllFtSaleshExtByGroup3AndBrandAndSupplier`, itemFilter, { headers: authHeader() });
    }

    getSumQtyFmaterialSalesdItems(itemFilter){
        return axios.post(API_URL + `getSumQtyFmaterialSalesdItems`, itemFilter, { headers: authHeader() });
    }
    getSumQtyFmaterialSalesdItemsGroupByMaterialAndMonth(itemFilter){
        return axios.post(API_URL + `getSumQtyFmaterialSalesdItemsGroupByMaterialAndMonth`, itemFilter, { headers: authHeader() });
    }
    getSumQtyFmaterialSalesdItemsAverageByFdivisionBean(itemFilter){
        return axios.post(API_URL + `getSumQtyFmaterialSalesdItemsAverageByFdivisionBean`, itemFilter, { headers: authHeader() });
    }
    getSumQtyFmaterialSalesdItemsAverageByFdivisionBeanAndDays(itemFilter){
        return axios.post(API_URL + `getSumQtyFmaterialSalesdItemsAverageByFdivisionBeanAndDays`, itemFilter, { headers: authHeader() });
    }


    getSumValueFmaterialSalesdItems(itemFilter){
        return axios.post(API_URL + `getSumValueFmaterialSalesdItems`, itemFilter, { headers: authHeader() });
    }
    getSumTotalEndUserByCustomer(itemFilter){
        return axios.post(API_URL + `getSumTotalEndUserByCustomer`, itemFilter, { headers: authHeader() });
    }
    getSumTotalAmountGroupByCustomer(itemFilter){
        return axios.post(API_URL + `getSumTotalAmountGroupByCustomer`, itemFilter, { headers: authHeader() });
    }



}
export default new FtSalesExtService()
