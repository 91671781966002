<template>
  <div>
    <apexcharts
        width="350"
        :options="chartOptionsComputed"
        :series="series"
    ></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: "PieSalesContributions",
  props:{
    brandOrGroup3Supplier: String,
    brandLabels: [],
    brandSeries: [],
    group3Labels: [],
    group3Series: []
  },
  components: {
    apexcharts: VueApexCharts,
  },
  watch:{
    brandSeries: {
      handler:function (value) {
        if (value.length > 0 && this.brandOrGroup3Supplier ==='BRAND'){
          this.series = value
        }
      }
    },
    group3Series: {
      handler:function (value) {
        if (value.length > 0 && this.brandOrGroup3Supplier ==='GROUP3'){
          this.series = value
        }
      }
    }

  },
  data: function() {
    return {
      labels: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'],
      series: [1,2,3,4,5,6,7,8,9, 10, 12, 13, 14, 15],
    }
  },
  computed:{
    labelsComputed(){
      let values = []
      if (this.brandOrGroup3Supplier === 'BRAND'){
        values = this.brandLabels
      }else {
        values = this.group3Labels
      }
      return values;
    },
    chartOptionsComputed() {
      let chartOptions= {
        chart: {
          width: 380,
              type: 'pie',
        },
        labels: this.labelsComputed,

        /**
         * Kasih gradient lebih cantik dikit
         */
        fill: {
          type: 'gradient',
        },

        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
              type: 'pie'
            },
            legend: {
              position: 'bottom'
            }
          },
        }]
      }
      return chartOptions
    }
  },
  methods:{
  }

}
</script>

<style scoped>

</style>